.main {
    background-image: url('../src/assets/images/background.png');
    background-repeat: no-repeat;
    height: 100vh;
    background-size: cover;

    .logo-company {
        position: absolute;
        top: 40px;
        left: 40px;

        img {
            width: 238px;
            height: 38px;
        }
    }
}