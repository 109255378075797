.sn-lookup-page {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    .search-section {
        .card {
            .card-body {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                gap: 12px;
                padding: 28px 40px;

                h5 {
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 140%;
                    color: $neutral-black;
                    margin-bottom: 0px;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: $black-color-2;
                    margin-bottom: 0px;
                }

                .sn-form {
                    .input-form-sn {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        gap: 20px;

                        .input-with-icon__adv {
                            width: 70%;

                            .form-control {
                                padding: 12px 14px;
                                padding-left: 35px;
                            }
                        }

                        .btn {
                            border-radius: 40px;
                            color: $white;
                            background-color: $green-color-1;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 24px;
                            width: 31%;
                        }
                    }
                }
            }
        }
    }

    .result-section {
        padding-top: 60px;
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        justify-content: space-around;

        .result-section-title {
            text-align: center;

            h5 {
                font-size: 32px;
                font-weight: 600;
                line-height: 45px;
                color: $neutral-black;
                margin-bottom: 0px;
            }

            p {
                font-size: 36px;
                font-weight: 800;
                line-height: 50px;
                color: $green-color-1;
                margin-bottom: 0px;
            }
        }

        .result-section-content {
            display: flex;
            gap: 50px;
            width: 100%;

            .result-image {
                border-radius: 20px;

                img {
                    width: 377px;
                    height: 491px;
                    object-fit: cover;
                }
            }

            .result-description {
                width: 100%;

                .card {
                    border-bottom: 3px solid $green-color-1;

                    .card-body {
                        h5 {
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 34px;

                        }

                        .result-card-content {
                            display: flex;
                            gap: 40px;

                            .content-left,
                            .content-right {
                                width: 50%;
                            }

                            .content-right {
                                p {
                                    text-align: right;
                                }
                            }

                            .content-row {
                                display: flex;
                                gap: 4px;


                            }

                            label {
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 24px;
                                color: #787878;
                                width: 60%;
                            }

                            p {
                                width: 40%;
                                font-size: 18px;
                                font-weight: 600;
                                line-height: 24px;
                                color: $neutral-black;
                            }

                            &.full {
                                label {
                                    width: 24%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .result-section-action {
            a {
                background-color: $green-color-1;
                color: $white;
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
                border-radius: 24px;
            }
        }
    }

    @media #{$large-mobile, $small-mobile, $extra-small-mobile} {
        overflow-y: auto;

        .search-section {
            .card {
                .card-body {
                    text-align: center;
                    gap: 12px;

                    h5 {
                        font-size: 16px;
                    }

                    p {
                        font-size: 14px;
                    }

                    .sn-form {
                        flex-direction: column;

                        .input-with-icon__adv,
                        a {
                            width: 100% !important;
                        }
                    }
                }
            }
        }

        .result-section {
            padding-top: 100px;
            justify-content: flex-start;

            .result-section-title {
                h5 {
                    font-size: 14px;
                    line-height: 19.6px;
                }

                p {
                    font-size: 16px;
                    line-height: 22.4px;
                }
            }

            .result-section-content {
                flex-direction: column;
                align-items: center;

                .result-image {
                    img {
                        width: 197px;
                        height: 257px;

                    }
                }

                .result-description {
                    h5 {
                        font-size: 16px;
                    }

                    .result-card-content {
                        flex-direction: column;
                        gap: 8px !important;

                        .content-left,
                        .content-right {
                            width: 100% !important;

                            p {
                                text-align: left !important;
                            }
                        }

                        label,
                        p {
                            font-size: 16px !important;
                        }

                        &.full {
                            width: 100%;
                            gap: 8px !important;

                            label,
                            p {
                                width: 100% !important;
                            }
                        }
                    }

                }
            }
        }
    }
}