.btn {
    color: $white;
    border-radius: $border-radius;
    padding: 10px 16px 10px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    &.btn-sm {
        padding: 8px, 14px, 8px, 14px;
    }

    &.btn-lg {
        padding: 10px, 18px, 10px, 18px;
        font-size: 16px;
        line-height: 24px;
    }

    &.btn-xl {
        padding: 12px, 20px, 12px, 20px;
        font-size: 16px;
        line-height: 24px;
    }

    &.btn-xxl {
        padding: 16px, 28px, 16px, 28px;
        font-size: 18px;
        line-height: 28px;
    }

    &.btn-primary {
        background-color: $primary-color;

        &:disabled {
            background-color: #E9D7FE;
        }
    }

    &.btn-secondary {
        background-color: #F9F5FF;

        &:disabled {
            // background-color: ;
        }
    }
}