//variable

//color system
$white : #fff;
$black: #000;
$primary-color: #16296D;
$neutral-black: #070E24;
$neutral-black-2:#959FBF;
$black-color-1: #2E2E2E;

$black-color-2: #959FBF;
$black-color-3: #BEC3D6;
$black-color-4: #FAFAFA;

$blue-color-1: #16296D;
$blue-color-2: #213EA4;
$blue-color-3: #3155D5;
$blue-color-4: #9EAFEB;
$blue-color-5: #D5DCF6;
$blue-color-6: #E8EAF0;
$blue-color-7: #F2F4FD;

$green-color-1: #12A533;
$green-color-2: #17D742;
$green-color-3: #6DEF8B;
$green-color-4: #D1FADA;
$green-color-5: #E7F6EB;
$green-color-6: #F7FCF9;

$filter-black : invert(0%) sepia(95%) saturate(7446%) hue-rotate(195deg) brightness(85%) contrast(111%);

$state-info-color : #2F80ED;
$state-success-color : #2EBE52;
$state-warning-color : #FFAB00;
$state-error-color : #EB5757;
$state-error-dark-color : #C82A2A;

$required-color: #F60933;

$custom-control-indicator-size: 1rem;
$custom-control-indicator-bg: $white;
$custom-control-indicator-bg-size: 50% 50%;


//components
$border-radius: 8px;

$xlmax-device: "only screen and (min-width: 1200px) and (max-width: 2500px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1499px)";
$desktop-device: "only screen and (min-width: 992px) and (max-width: 1199px)";
$tablet-device: "only screen and (min-width: 768px) and (max-width: 991px)";
$large-mobile: "only screen and (max-width: 767px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (max-width: 479px)";