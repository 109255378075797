// Input
input,
textarea {
    &:focus {
        outline: none;
        border-color: $primary;
    }

    &.form-control {
        border-color: $blue-color-6;
        color: $black;
        font-size: 16px;
        line-height: 20px;

        &.required {
            border-color: $required-color;
        }

        &::placeholder {
            color: $neutral-black-2;
        }
    }
}