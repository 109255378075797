.input-with-icon__adv {
    display: flex;
    align-items: center;
    position: relative;

    &.prepend {
        .icon-prepend {
            position: absolute;
            left: 10px;

            i {
                color: $neutral-black-2;
            }
        }

        .form-control {
            padding-left: 35px;
        }
    }

    &.append {
        .icon-append {
            position: absolute;
            right: 10px;

            i {
                color: $neutral-black-2;
            }
        }

        .form-control {
            padding-left: 25px;
        }
    }

}